import * as React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"

import {Link, useTranslation, useI18next, Trans} from "gatsby-plugin-react-i18next"

import {BsBackspace} from "react-icons/bs"
import {FaLink} from "react-icons/fa"

import {Tech, TechArray} from "../components/tech"
import  PortfolioPreview  from "../components/portfolio-preview"
import PortfolioType from "../components/portfolio-type"
import SEO from "../components/seo"


const OpenPage = ({url}) => {

  const { t } = useTranslation()

  return(
    <div className="inline-flex pt-2 px-5">
      {t('open')}  <a className="ml-2 hover:text-icon_color-active" href={url}><FaLink size={24} /></a>
    </div>
  )
}


const PortfolioItemTpl = ({ pageContext }) => {

  const { t } = useTranslation()
  const { language } = useI18next()

  const { technology, type, name, description, images, url } = pageContext

  return (
    <Layout lightBackground>
      <SEO title={t('navigation.portfolio')} />

      
      <div className="container w-5/6 mx-auto lg:pl-10"> 
        <div className="flex flex-col lg:flex-row  w-full pt-10 place-content-between">

          <div className="flex flow-col lg:flex-row">

            <div className="p-1 h-fit w-fit my-1 mr-1">
            <Link to="/portfolio">
              <BsBackspace className="hover:text-icon_color-active"  size={48}/>
            </Link>
             
            </div>

            <PortfolioType type={type} />
            <div className="flex flex-col  ml-3">
                  <h1 className="text-4xl">{name[language]}</h1>
                  <h3>{description[language]}</h3>
            </div>
          </div>
          
          <div className="inline-flex mt-3"> {url ? <OpenPage url={url} /> : ""} <h2 className="pt-2">Tech Stack: </h2> <TechArray langList={technology} /> </div>
          
        </div>
        <hr className="mt-5 mb-5" />

        <div className="flex flex-col w-full">
        {images.map(img => (
          <div className=" mx-auto gap-4 mb-10">
              <PortfolioPreview name={img} />
          </div>
          ))}
          


        </div>


      </div>

  
      

    </Layout>
  )
}

export default PortfolioItemTpl

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    logo: file(base: {eq: "rocket.png"}) {
      childrenImageSharp{
              gatsbyImageData(width: 540, quality: 100, placeholder: DOMINANT_COLOR) 
      }
    }
  }
`;
