import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PortfolioPreview = ({name}) => {
    const data = useStaticQuery(graphql`query PreviewQuery {
      allImageSharp {
        nodes {
          fluid {
            originalName
          }
          gatsbyImageData(width: 900, quality: 85, placeholder: DOMINANT_COLOR) 
        }
      }
    }
  `)
  
  const filteredData = data.allImageSharp.nodes.filter( (element, index, array) => element.fluid.originalName === name )
  const { gatsbyImageData } = filteredData.pop()
  const image = getImage(gatsbyImageData)
  
    return(
      <GatsbyImage image={image} sizes="(max-width: 900px) 900px" alt="preview"/>
    )
  }

  export default PortfolioPreview