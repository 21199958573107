import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { GiPaintRoller } from "react-icons/gi"
import {FaMobileAlt, FaInternetExplorer,FaFirefoxBrowser, FaTools} from "react-icons/fa"


// Design
// Android
// Web
// Other


const PortfolioType = ({type, className, size = 64}) => {

    // relative -top-72 bg-icon_color-active p-1 rounded mx-auto

    let DynamicComponent

    switch (type) {
        case 'web':
            DynamicComponent = FaFirefoxBrowser
            break
        case 'mobile':
            DynamicComponent = FaMobileAlt
            break
        case 'other':
            DynamicComponent = FaTools
            break
        case 'design':
            DynamicComponent = GiPaintRoller
            break
        default:
            DynamicComponent = FaTools
            break
    }

    return(
        <DynamicComponent className={`${className} bg-icon_color-active p-1 rounded`} size={size} />
    )

}

export default PortfolioType