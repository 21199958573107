import React from "react"

import {DiJavascript1, DiJava, DiAndroid, DiAws, DiHtml5, DiCss3, DiWordpress, DiTerminal} from "react-icons/di"
import {SiUnrealengine, SiBlender, SiGimp, SiWebgl} from "react-icons/si"


const TechEnum= {
    JAVASCRIPT: 'js',
    JAVA: 'java',
    ANDROID: 'android',
    CSS: 'css',
    AWS: 'aws',
    G3D : '3d',
    UE4: 'ue4',
    HTML: 'html',
    DEVOPS: 'devops',
    WORDPRESS: 'wp',
    GRAPHIC2D : '2d',
    WEBGL : 'webgl'

}


const Tech = ({langType, size = 36}) => {

    let DynamicComponent = DiJava

    switch (langType) {
        case TechEnum.JAVASCRIPT:
            DynamicComponent = DiJavascript1
            break
        case TechEnum.ANDROID.toString():
            DynamicComponent = DiAndroid
            break
        case TechEnum.AWS.toString():
            DynamicComponent = DiAws
            break
        case TechEnum.HTML.toString():
            DynamicComponent = DiHtml5
            break
        case TechEnum.CSS.toString():
            DynamicComponent = DiCss3
            break
        case TechEnum.WORDPRESS.toString():
            DynamicComponent = DiWordpress
            break
        case TechEnum.DEVOPS.toString():
            DynamicComponent = DiTerminal
            break
        case TechEnum.JAVA.toString():
            DynamicComponent = DiJava
            break
        case TechEnum.G3D.toString():
            DynamicComponent = SiBlender
            break
        case TechEnum.UE4.toString():
            DynamicComponent = SiUnrealengine
            break
        case TechEnum.GRAPHIC2D:
            DynamicComponent = SiGimp
            break  
        case TechEnum.WEBGL:
            DynamicComponent = SiWebgl
            break  
    }

    return(<DynamicComponent size={size} />)
}

const TechArray = ({langList, size}) => {
    return(
        <React.Fragment>
            {langList.map(lang => <Tech size={size} langType={lang} />)}
        </React.Fragment>
    )
}

export  {Tech, TechArray, TechEnum}
